// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Knowledge from 'components/Contentful/Knowledge'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Bestätigung nicht mehr Aktuell"
        meta={[
          { name: 'description', content: '' },
          { name: 'keywords', content: '' },
        ]}
      >
        
      </Helmet>
      <Helmet meta={[{ name: 'robots', content: 'noindex, nofollow' }]}></Helmet>
      <div
          className='bg__flex--white'
          key='6W3Ry7tnFhxjDNIy28Mi2D-4LeDQbJ5PHzNHTgAgpeiRt'
          id='vielen-dank-anfrage-geloescht'
        >
          <Knowledge
            {...{
    "type": "knowledge",
    "id": "4LeDQbJ5PHzNHTgAgpeiRt",
    "updatedAt": "2020-02-14T13:52:40.031Z",
    "title": "Bestätigung",
    "description": "<p>Wir haben Ihr Update erhalten. Schade dass Sie an unseren Produkten kein Interesse mehr haben. Falls Ihr Projekt zu einem späteren Zeitpunkt wieder aktuell wird, können Sie sich jederzeit wieder bei uns melden.</p>\n<p>😥</p>\n",
    "link": {
        "type": "link",
        "id": "6cbzcLrAhUSwSOUEA22eqe",
        "updatedAt": "2018-10-15T14:30:12.902Z",
        "label": "zurück zur Webseite",
        "url": "https://www.heizungsmacher.ch"
    },
    "anchorName": "vielen-dank-anfrage-geloescht",
    "details": []
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
